import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0091 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0070/'
  )
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0072/'
  )
  const routeParam3 = useRouteParam('https://www.kintaicloud.jp/')
  const routeParam4 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0071/'
  )
  const routeParam5 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0067/'
  )
  const routeParam6 = useRouteParam('https://www.kintaicloud.jp/')
  return (
    <Wrapper>
      <ColumnH1 label="派遣会社に最適な勤怠管理システムとは？必要機能や導入する際の注意点を紹介" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.02.28</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0091.jpg"
          alt="キンクラコラム画像91"
        />
        <p>
          事業規模がまだ小さいうちは、Excel(エクセル)による管理でも問題はありませんが、規模が大きくなれば管理しきることは難しくなるでしょう。
          <br />
          また、Excelによる管理の場合、派遣社員の勤怠状況をリアルタイムに把握することも難しくなります。そのため、勤怠管理システムの導入を検討している企業も多いかと思います。
          <br />
          とはいえ、<span>派遣会社に最適な勤怠管理システム</span>
          とはどういうものなのか分からないという方も多いでしょう。そこでこの記事では、派遣会社が勤怠管理システムを導入するべき理由や、派遣会社に最適な勤怠管理システムとはどういうものなのかを紹介します。
        </p>
        <div id="toc1">
          <h1>派遣会社が勤怠管理システムを導入するべき理由とは</h1>
        </div>
        <p>
          派遣会社が勤怠管理システムを導入するべき理由とは何か？メリットをご紹介します。
        </p>
        <div id="toc1-1">
          <h2>労働時間をリアルタイムで把握できる</h2>
        </div>
        <p>
          先述したように、派遣社員は派遣先企業で労働を行います。したがって、派遣社員からの報告が無いと、労働時間を把握することが出来ません。そのため、派遣社員がオーバーワークをしているにも関わらず、労働時間を把握することが出来ないため対処することが出来ないといったことが発生する可能性があります。
          <br />
          このような問題は、勤怠管理システムを導入することで解決可能です。
          <br />
          システムによる勤怠管理を行えば、
          <span>派遣社員の労働時間をリアルタイムで把握</span>
          することが出来るようになるため、オーバーワークを事前に防ぐことが可能になります。
        </p>
        <div id="toc1-2">
          <h2>勤怠管理を効率化することが出来る</h2>
        </div>
        <p>
          勤怠管理システムは、<span>各派遣社員の集計を自動で行ってくれる</span>
          ため、勤怠管理を効率化することが出来ます。特に、Excelを使って手動で管理をしている場合、各派遣社員からの報告があるまで集計作業をすることが出来ません。
          <br />
          そのため、集計を行うまでに時間がかかってしまいます。しかし、勤怠管理システムを導入すれば、リアルタイムかつ自動で集計をしてくれるため、集計の手間を省くことが出来ます。
          <br />
          また、打刻漏れなどがあった際にもリアルタイムで把握することが出来るため、ミスを減らすことも可能です。
          <br />
          <br />
          以上の理由から、勤怠管理システムを導入することをおすすめします。
        </p>
        <a href={routeParam} className="column-detail-link">
          関連記事：Excel(エクセル)でできる勤怠管理の限界とシステム導入で得られるメリットとは？
        </a>
        <div id="toc2">
          <h1>派遣会社に最適な勤怠管理システムを選ぶ際のポイントとは</h1>
        </div>
        <p>
          派遣会社に最適な勤怠管理システムとはどのようなものなのか？
          <br />
          ここでは、派遣会社が勤怠管理システムを選ぶ際のポイントをいくつかご紹介します。
        </p>
        <div id="toc2-1">
          <h2>各種申請機能はついているか</h2>
        </div>
        <p>
          勤務報告以外にも、有給や残業申請のできる機能がついていると便利です。
          <br />
          特に、派遣先から残業の依頼があった際に、システム上で申請ができるとスムーズに処理することが可能になります。
        </p>
        <a href={routeParam2} className="column-detail-link">
          関連記事：有給管理を効率化する勤怠管理システムの選び方やお勧めのシステムをご紹介
        </a>
        <div id="toc2-2">
          <h2>多様に打刻出来るか</h2>
        </div>
        <p>
          派遣先によって、働く環境は異なってきます。そのため、すべて同じ方法で打刻することが出来るとは限りません。
          <br />
          したがって、様々な労働環境に対応できるように、多様な打刻方法ができる勤怠管理システムを選定することが重要になります。
          <br />
          おすすめの機能としては、個人が持っているスマートフォンから打刻ができる勤怠管理システムです。スマートフォンであれば、場所によらず、
          <span>どのような労働環境でも打刻できます。</span>
        </p>
        <div id="toc2-3">
          <h2>シフト管理機能がついているか</h2>
        </div>
        <p>
          シフトを作成する際に、派遣社員の出勤希望と派遣先の希望を照らし合わせて作成するのは労力がかかります。しかし、シフト管理機能があればこの問題を解決できます。
          <br />
          シフト管理機能とは、派遣社員が入力した内容と、派遣先の希望を自動で集計してシフトを作成してくれる機能です。これにより、シフトを作成する労力を減らすことが可能になります。
          <br />
          <br />
          以上のポイントを押さえて勤怠管理システムを選定することをおすすめします。
          <br />
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc3">
          <h1>派遣会社が勤怠管理システムを導入する際の注意点</h1>
        </div>
        <img
          src="/images/column/details/c0091_g01.jpg"
          alt="キンクラコラム画像91"
        />
        <p>
          勤怠管理システムを導入する際には、いくつか注意点があります。
          <br />
          勤怠管理システムを導入してみたものの、自社の環境と合わずコストだけがかかってしまったといったリスクを負わないためにも注意する必要があります。
        </p>
        <div id="toc3-1">
          <h2>自社に必要な機能は何かを洗い出す</h2>
        </div>
        <p>
          勤怠管理システムを導入する前に、自社に必要な機能は何かを洗い出しておく必要があります。勤怠管理システムを導入した後で機能が足りていなかったことが発覚してしまった場合、余計なコストがかかってしまうからです。
          <br />
          そのため、まずは自社に必要な機能は何かを洗い出した上で、それを満たす勤怠管理システムを選定していくことが大切です。
        </p>
        <a href={routeParam3} className="column-detail-link">
          ⇒無料で全機能がお試しできるクラウド型勤怠管理システム「キンクラ」とは
        </a>
        <div id="toc3-2">
          <h2>費用対効果が見合っているかを確認する</h2>
        </div>
        <p>
          派遣会社向けの勤怠管理システムの場合、様々な機能が必要になるため、比較的割高になってしまう可能性があります。そのため、勤怠管理システムを導入することによって費用対効果が妥当かどうかを確認する必要があります。
          <br />
          実際に勤怠管理システムを導入してみたものの、自社の規模と合わずコストが余計にかかってしまうといった恐れがあるため、どれくらいの費用がかかるのか確認することが大切です。
        </p>
        <a href={routeParam4} className="column-detail-link">
          関連記事：勤怠管理システムの費用対効果を最大限にできるコストの見直しとは？
        </a>
        <div id="toc3-3">
          <h2>クラウド型かどうかを確認する</h2>
        </div>
        <p>
          勤怠管理システムには、オンプレミス型とクラウド型の2種類があります。
          <br />
          オンプレミス型とは自社でサーバーを用意してシステムを利用するものです。
          <br />
          反対に、クラウド型とはサービス提供事業者が用意しているサーバーにログインしてシステムを使用するタイプのものです。
          <br />
          派遣会社が勤怠管理システムを導入する場合、
          <span>クラウド型のものをおすすめします。</span>
          <br />
          オンプレミス型の場合、自社でサーバーを用意するため、初期費用が高くなるためです。
          <br />
          しかし、クラウド型ならば導入費用は安く済みます。
          <br />
          また、導入環境を選ばないため、スマホから打刻をするといったことも可能になります。
        </p>
        <a href={routeParam5} className="column-detail-link">
          関連記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
        </a>
        <div id="toc4">
          <h1>派遣会社の勤怠管理システム導入はコスト削減につながる</h1>
        </div>
        <p>
          特定の機能を備えたシステムを導入することで、派遣会社は業務をよりスムーズに、そして効率的に運営することが可能となります。派遣会社の皆様も、ぜひ勤怠管理システムの導入をご検討ください。
          <br />
          <br />
          派遣会社に適している勤怠管理システムに「キンクラ」があります。
          <br />
          キンクラには、人気のある「経費精算機能」や「各種申請機能」などがそろっており、派遣会社にありがちな勤怠管理の問題点を解決できます。
          <br />
          ぜひこの機会に、キンクラをご検討ください。
        </p>
        <a href={routeParam6} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0091
